

export const CountryCodeInfo = {
  "AD": {
    "name": "Andorra",
    "flag": "https://flagcdn.com/w320/ad.png",
    "code": "AD"
  },
  "AE": {
    "name": "United Arab Emirates",
    "flag": "https://flagcdn.com/w320/ae.png",
    "code": "AE"
  },
  "AF": {
    "name": "Afghanistan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
    "code": "AF"
  },
  "AG": {
    "name": "Antigua and Barbuda",
    "flag": "https://flagcdn.com/w320/ag.png",
    "code": "AG"
  },
  "AI": {
    "name": "Anguilla",
    "flag": "https://flagcdn.com/w320/ai.png",
    "code": "AI"
  },
  "AL": {
    "name": "Albania",
    "flag": "https://flagcdn.com/w320/al.png",
    "code": "AL"
  },
  "AM": {
    "name": "Armenia",
    "flag": "https://flagcdn.com/w320/am.png",
    "code": "AM"
  },
  "AN": {
    "name": "Netherlands Antilles",
    "flag": "",
    "code": "AN"
  },
  "AO": {
    "name": "Angola",
    "flag": "https://flagcdn.com/w320/ao.png",
    "code": "AO"
  },
  "AQ": {
    "name": "Antarctica",
    "flag": "https://flagcdn.com/w320/aq.png",
    "code": "AQ"
  },
  "AR": {
    "name": "Argentina",
    "flag": "https://flagcdn.com/w320/ar.png",
    "code": "AR"
  },
  "AS": {
    "name": "American Samoa",
    "flag": "https://flagcdn.com/w320/as.png",
    "code": "AS"
  },
  "AT": {
    "name": "Austria",
    "flag": "https://flagcdn.com/w320/at.png",
    "code": "AT"
  },
  "AU": {
    "name": "Australia",
    "flag": "https://flagcdn.com/w320/au.png",
    "code": "AU"
  },
  "AW": {
    "name": "Aruba",
    "flag": "https://flagcdn.com/w320/aw.png",
    "code": "AW"
  },
  "AX": {
    "name": "Åland Islands",
    "flag": "https://flagcdn.com/w320/ax.png",
    "code": "AX"
  },
  "AZ": {
    "name": "Azerbaijan",
    "flag": "https://flagcdn.com/w320/az.png",
    "code": "AZ"
  },
  "BA": {
    "name": "Bosnia and Herzegovina",
    "flag": "https://flagcdn.com/w320/ba.png",
    "code": "BA"
  },
  "BB": {
    "name": "Barbados",
    "flag": "https://flagcdn.com/w320/bb.png",
    "code": "BB"
  },
  "BD": {
    "name": "Bangladesh",
    "flag": "https://flagcdn.com/w320/bd.png",
    "code": "BD"
  },
  "BE": {
    "name": "Belgium",
    "flag": "https://flagcdn.com/w320/be.png",
    "code": "BE"
  },
  "BF": {
    "name": "Burkina Faso",
    "flag": "https://flagcdn.com/w320/bf.png",
    "code": "BF"
  },
  "BG": {
    "name": "Bulgaria",
    "flag": "https://flagcdn.com/w320/bg.png",
    "code": "BG"
  },
  "BH": {
    "name": "Bahrain",
    "flag": "https://flagcdn.com/w320/bh.png",
    "code": "BH"
  },
  "BI": {
    "name": "Burundi",
    "flag": "https://flagcdn.com/w320/bi.png",
    "code": "BI"
  },
  "BJ": {
    "name": "Benin",
    "flag": "https://flagcdn.com/w320/bj.png",
    "code": "BJ"
  },
  "BL": {
    "name": "Saint Barthelemy",
    "flag": "https://flagcdn.com/w320/bl.png",
    "code": "BL"
  },
  "BM": {
    "name": "Bermuda",
    "flag": "https://flagcdn.com/w320/bm.png",
    "code": "BM"
  },
  "BN": {
    "name": "Brunei",
    "flag": "https://flagcdn.com/w320/bn.png",
    "code": "BN"
  },
  "BO": {
    "name": "Bolivia",
    "flag": "https://flagcdn.com/w320/bo.png",
    "code": "BO"
  },
  "BQ": {
    "name": "Bonaire, Sint Eustatius and Saba",
    "flag": "https://flagcdn.com/w320/bq.png",
    "code": "BQ"
  },
  "BR": {
    "name": "Brazil",
    "flag": "https://flagcdn.com/w320/br.png",
    "code": "BR"
  },
  "BS": {
    "name": "Bahamas",
    "flag": "https://flagcdn.com/w320/bs.png",
    "code": "BS"
  },
  "BT": {
    "name": "Bhutan",
    "flag": "https://flagcdn.com/w320/bt.png",
    "code": "BT"
  },
  "BW": {
    "name": "Botswana",
    "flag": "https://flagcdn.com/w320/bw.png",
    "code": "BW"
  },
  "BY": {
    "name": "Belarus",
    "flag": "https://flagcdn.com/w320/by.png",
    "code": "BY"
  },
  "BZ": {
    "name": "Belize",
    "flag": "https://flagcdn.com/w320/bz.png",
    "code": "BZ"
  },
  "CA": {
    "name": "Canada",
    "flag": "https://flagcdn.com/w320/ca.png",
    "code": "CA"
  },
  "CC": {
    "name": "Cocos Islands",
    "flag": "https://flagcdn.com/w320/cc.png",
    "code": "CC"
  },
  "CD": {
    "name": "Democratic Republic of the Congo",
    "flag": "https://flagcdn.com/w320/cd.png",
    "code": "CD"
  },
  "CF": {
    "name": "Central African Republic",
    "flag": "https://flagcdn.com/w320/cf.png",
    "code": "CF"
  },
  "CG": {
    "name": "Republic of the Congo",
    "flag": "https://flagcdn.com/w320/cg.png",
    "code": "CG"
  },
  "CH": {
    "name": "Switzerland",
    "flag": "https://flagcdn.com/w320/ch.png",
    "code": "CH"
  },
  "CI": {
    "name": "Ivory Coast",
    "flag": "https://flagcdn.com/w320/ci.png",
    "code": "CI"
  },
  "CK": {
    "name": "Cook Islands",
    "flag": "https://flagcdn.com/w320/ck.png",
    "code": "CK"
  },
  "CL": {
    "name": "Chile",
    "flag": "https://flagcdn.com/w320/cl.png",
    "code": "CL"
  },
  "CM": {
    "name": "Cameroon",
    "flag": "https://flagcdn.com/w320/cm.png",
    "code": "CM"
  },
  "CN": {
    "name": "China",
    "flag": "https://flagcdn.com/w320/cn.png",
    "code": "CN"
  },
  "CO": {
    "name": "Colombia",
    "flag": "https://flagcdn.com/w320/co.png",
    "code": "CO"
  },
  "CR": {
    "name": "Costa Rica",
    "flag": "https://flagcdn.com/w320/cr.png",
    "code": "CR"
  },
  "CU": {
    "name": "Cuba",
    "flag": "https://flagcdn.com/w320/cu.png",
    "code": "CU"
  },
  "CV": {
    "name": "Cape Verde",
    "flag": "https://flagcdn.com/w320/cv.png",
    "code": "CV"
  },
  "CW": {
    "name": "Curacao",
    "flag": "https://flagcdn.com/w320/cw.png",
    "code": "CW"
  },
  "CX": {
    "name": "Christmas Island",
    "flag": "https://flagcdn.com/w320/cx.png",
    "code": "CX"
  },
  "CY": {
    "name": "Cyprus",
    "flag": "https://flagcdn.com/w320/cy.png",
    "code": "CY"
  },
  "CZ": {
    "name": "Czech Republic",
    "flag": "https://flagcdn.com/w320/cz.png",
    "code": "CZ"
  },
  "DE": {
    "name": "Germany",
    "flag": "https://flagcdn.com/w320/de.png",
    "code": "DE"
  },
  "DJ": {
    "name": "Djibouti",
    "flag": "https://flagcdn.com/w320/dj.png",
    "code": "DJ"
  },
  "DK": {
    "name": "Denmark",
    "flag": "https://flagcdn.com/w320/dk.png",
    "code": "DK"
  },
  "DM": {
    "name": "Dominica",
    "flag": "https://flagcdn.com/w320/dm.png",
    "code": "DM"
  },
  "DO": {
    "name": "Dominican Republic",
    "flag": "https://flagcdn.com/w320/do.png",
    "code": "DO"
  },
  "DZ": {
    "name": "Algeria",
    "flag": "https://flagcdn.com/w320/dz.png",
    "code": "DZ"
  },
  "EC": {
    "name": "Ecuador",
    "flag": "https://flagcdn.com/w320/ec.png",
    "code": "EC"
  },
  "EE": {
    "name": "Estonia",
    "flag": "https://flagcdn.com/w320/ee.png",
    "code": "EE"
  },
  "EG": {
    "name": "Egypt",
    "flag": "https://flagcdn.com/w320/eg.png",
    "code": "EG"
  },
  "EH": {
    "name": "Western Sahara",
    "flag": "https://flagcdn.com/w320/eh.png",
    "code": "EH"
  },
  "ER": {
    "name": "Eritrea",
    "flag": "https://flagcdn.com/w320/er.png",
    "code": "ER"
  },
  "ES": {
    "name": "Spain",
    "flag": "https://flagcdn.com/w320/es.png",
    "code": "ES"
  },
  "ET": {
    "name": "Ethiopia",
    "flag": "https://flagcdn.com/w320/et.png",
    "code": "ET"
  },
  "FI": {
    "name": "Finland",
    "flag": "https://flagcdn.com/w320/fi.png",
    "code": "FI"
  },
  "FJ": {
    "name": "Fiji",
    "flag": "https://flagcdn.com/w320/fj.png",
    "code": "FJ"
  },
  "FK": {
    "name": "Falkland Islands",
    "flag": "https://flagcdn.com/w320/fk.png",
    "code": "FK"
  },
  "FM": {
    "name": "Micronesia",
    "flag": "https://flagcdn.com/w320/fm.png",
    "code": "FM"
  },
  "FO": {
    "name": "Faroe Islands",
    "flag": "https://flagcdn.com/w320/fo.png",
    "code": "FO"
  },
  "FR": {
    "name": "France",
    "flag": "https://flagcdn.com/w320/fr.png",
    "code": "FR"
  },
  "GA": {
    "name": "Gabon",
    "flag": "https://flagcdn.com/w320/ga.png",
    "code": "GA"
  },
  "GB": {
    "name": "United Kingdom",
    "flag": "https://flagcdn.com/w320/gb.png",
    "code": "GB"
  },
  "GD": {
    "name": "Grenada",
    "flag": "https://flagcdn.com/w320/gd.png",
    "code": "GD"
  },
  "GE": {
    "name": "Georgia",
    "flag": "https://flagcdn.com/w320/ge.png",
    "code": "GE"
  },
  "GF": {
    "name": "French Guiana",
    "flag": "https://flagcdn.com/w320/gf.png",
    "code": "GF"
  },
  "GG": {
    "name": "Guernsey",
    "flag": "https://flagcdn.com/w320/gg.png",
    "code": "GG"
  },
  "GH": {
    "name": "Ghana",
    "flag": "https://flagcdn.com/w320/gh.png",
    "code": "GH"
  },
  "GI": {
    "name": "Gibraltar",
    "flag": "https://flagcdn.com/w320/gi.png",
    "code": "GI"
  },
  "GL": {
    "name": "Greenland",
    "flag": "https://flagcdn.com/w320/gl.png",
    "code": "GL"
  },
  "GM": {
    "name": "Gambia",
    "flag": "https://flagcdn.com/w320/gm.png",
    "code": "GM"
  },
  "GN": {
    "name": "Guinea",
    "flag": "https://flagcdn.com/w320/gn.png",
    "code": "GN"
  },
  "GP": {
    "name": "Guadeloupe",
    "flag": "https://flagcdn.com/w320/gp.png",
    "code": "GP"
  },
  "GQ": {
    "name": "Equatorial Guinea",
    "flag": "https://flagcdn.com/w320/gq.png",
    "code": "GQ"
  },
  "GR": {
    "name": "Greece",
    "flag": "https://flagcdn.com/w320/gr.png",
    "code": "GR"
  },
  "GS": {
    "name": "South Georgia and South Sandwich Islands",
    "flag": "https://flagcdn.com/w320/gs.png",
    "code": "GS"
  },
  "GT": {
    "name": "Guatemala",
    "flag": "https://flagcdn.com/w320/gt.png",
    "code": "GT"
  },
  "GU": {
    "name": "Guam",
    "flag": "https://flagcdn.com/w320/gu.png",
    "code": "GU"
  },
  "GW": {
    "name": "Guinea-Bissau",
    "flag": "https://flagcdn.com/w320/gw.png",
    "code": "GW"
  },
  "GY": {
    "name": "Guyana",
    "flag": "https://flagcdn.com/w320/gy.png",
    "code": "GY"
  },
  "HK": {
    "name": "Hong Kong",
    "flag": "https://flagcdn.com/w320/hk.png",
    "code": "HK"
  },
  "HN": {
    "name": "Honduras",
    "flag": "https://flagcdn.com/w320/hn.png",
    "code": "HN"
  },
  "HR": {
    "name": "Croatia",
    "flag": "https://flagcdn.com/w320/hr.png",
    "code": "HR"
  },
  "HT": {
    "name": "Haiti",
    "flag": "https://flagcdn.com/w320/ht.png",
    "code": "HT"
  },
  "HU": {
    "name": "Hungary",
    "flag": "https://flagcdn.com/w320/hu.png",
    "code": "HU"
  },
  "ID": {
    "name": "Indonesia",
    "flag": "https://flagcdn.com/w320/id.png",
    "code": "ID"
  },
  "IE": {
    "name": "Ireland",
    "flag": "https://flagcdn.com/w320/ie.png",
    "code": "IE"
  },
  "IL": {
    "name": "Israel",
    "flag": "https://flagcdn.com/w320/il.png",
    "code": "IL"
  },
  "IM": {
    "name": "Isle of Man",
    "flag": "https://flagcdn.com/w320/im.png",
    "code": "IM"
  },
  "IN": {
    "name": "India",
    "flag": "https://flagcdn.com/w320/in.png",
    "code": "IN"
  },
  "IO": {
    "name": "British Indian Ocean Territory",
    "flag": "https://flagcdn.com/w320/io.png",
    "code": "IO"
  },
  "IQ": {
    "name": "Iraq",
    "flag": "https://flagcdn.com/w320/iq.png",
    "code": "IQ"
  },
  "IR": {
    "name": "Iran",
    "flag": "https://flagcdn.com/w320/ir.png",
    "code": "IR"
  },
  "IS": {
    "name": "Iceland",
    "flag": "https://flagcdn.com/w320/is.png",
    "code": "IS"
  },
  "IT": {
    "name": "Italy",
    "flag": "https://flagcdn.com/w320/it.png",
    "code": "IT"
  },
  "JE": {
    "name": "Jersey",
    "flag": "https://flagcdn.com/w320/je.png",
    "code": "JE"
  },
  "JM": {
    "name": "Jamaica",
    "flag": "https://flagcdn.com/w320/jm.png",
    "code": "JM"
  },
  "JO": {
    "name": "Jordan",
    "flag": "https://flagcdn.com/w320/jo.png",
    "code": "JO"
  },
  "JP": {
    "name": "Japan",
    "flag": "https://flagcdn.com/w320/jp.png",
    "code": "JP"
  },
  "KE": {
    "name": "Kenya",
    "flag": "https://flagcdn.com/w320/ke.png",
    "code": "KE"
  },
  "KG": {
    "name": "Kyrgyzstan",
    "flag": "https://flagcdn.com/w320/kg.png",
    "code": "KG"
  },
  "KH": {
    "name": "Cambodia",
    "flag": "https://flagcdn.com/w320/kh.png",
    "code": "KH"
  },
  "KI": {
    "name": "Kiribati",
    "flag": "https://flagcdn.com/w320/ki.png",
    "code": "KI"
  },
  "KM": {
    "name": "Comoros",
    "flag": "https://flagcdn.com/w320/km.png",
    "code": "KM"
  },
  "KN": {
    "name": "Saint Kitts and Nevis",
    "flag": "https://flagcdn.com/w320/kn.png",
    "code": "KN"
  },
  "KP": {
    "name": "North Korea",
    "flag": "https://flagcdn.com/w320/kp.png",
    "code": "KP"
  },
  "KR": {
    "name": "South Korea",
    "flag": "https://flagcdn.com/w320/kr.png",
    "code": "KR"
  },
  "KW": {
    "name": "Kuwait",
    "flag": "https://flagcdn.com/w320/kw.png",
    "code": "KW"
  },
  "KY": {
    "name": "Cayman Islands",
    "flag": "https://flagcdn.com/w320/ky.png",
    "code": "KY"
  },
  "KZ": {
    "name": "Kazakhstan",
    "flag": "https://flagcdn.com/w320/kz.png",
    "code": "KZ"
  },
  "LA": {
    "name": "Laos",
    "flag": "https://flagcdn.com/w320/la.png",
    "code": "LA"
  },
  "LB": {
    "name": "Lebanon",
    "flag": "https://flagcdn.com/w320/lb.png",
    "code": "LB"
  },
  "LC": {
    "name": "Saint Lucia",
    "flag": "https://flagcdn.com/w320/lc.png",
    "code": "LC"
  },
  "LI": {
    "name": "Liechtenstein",
    "flag": "https://flagcdn.com/w320/li.png",
    "code": "LI"
  },
  "LK": {
    "name": "Sri Lanka",
    "flag": "https://flagcdn.com/w320/lk.png",
    "code": "LK"
  },
  "LR": {
    "name": "Liberia",
    "flag": "https://flagcdn.com/w320/lr.png",
    "code": "LR"
  },
  "LS": {
    "name": "Lesotho",
    "flag": "https://flagcdn.com/w320/ls.png",
    "code": "LS"
  },
  "LT": {
    "name": "Lithuania",
    "flag": "https://flagcdn.com/w320/lt.png",
    "code": "LT"
  },
  "LU": {
    "name": "Luxembourg",
    "flag": "https://flagcdn.com/w320/lu.png",
    "code": "LU"
  },
  "LV": {
    "name": "Latvia",
    "flag": "https://flagcdn.com/w320/lv.png",
    "code": "LV"
  },
  "LY": {
    "name": "Libya",
    "flag": "https://flagcdn.com/w320/ly.png",
    "code": "LY"
  },
  "MA": {
    "name": "Morocco",
    "flag": "https://flagcdn.com/w320/ma.png",
    "code": "MA"
  },
  "MC": {
    "name": "Monaco",
    "flag": "https://flagcdn.com/w320/mc.png",
    "code": "MC"
  },
  "MD": {
    "name": "Moldova",
    "flag": "https://flagcdn.com/w320/md.png",
    "code": "MD"
  },
  "ME": {
    "name": "Montenegro",
    "flag": "https://flagcdn.com/w320/me.png",
    "code": "ME"
  },
  "MF": {
    "name": "Saint Martin",
    "flag": "https://flagcdn.com/w320/mf.png",
    "code": "MF"
  },
  "MG": {
    "name": "Madagascar",
    "flag": "https://flagcdn.com/w320/mg.png",
    "code": "MG"
  },
  "MH": {
    "name": "Marshall Islands",
    "flag": "https://flagcdn.com/w320/mh.png",
    "code": "MH"
  },
  "MK": {
    "name": "Macedonia",
    "flag": "https://flagcdn.com/w320/mk.png",
    "code": "MK"
  },
  "ML": {
    "name": "Mali",
    "flag": "https://flagcdn.com/w320/ml.png",
    "code": "ML"
  },
  "MM": {
    "name": "Myanmar",
    "flag": "https://flagcdn.com/w320/mm.png",
    "code": "MM"
  },
  "MN": {
    "name": "Mongolia",
    "flag": "https://flagcdn.com/w320/mn.png",
    "code": "MN"
  },
  "MO": {
    "name": "Macau",
    "flag": "https://flagcdn.com/w320/mo.png",
    "code": "MO"
  },
  "MP": {
    "name": "Northern Mariana Islands",
    "flag": "https://flagcdn.com/w320/mp.png",
    "code": "MP"
  },
  "MQ": {
    "name": "Martinique",
    "flag": "https://flagcdn.com/w320/mq.png",
    "code": "MQ"
  },
  "MR": {
    "name": "Mauritania",
    "flag": "https://flagcdn.com/w320/mr.png",
    "code": "MR"
  },
  "MS": {
    "name": "Montserrat",
    "flag": "https://flagcdn.com/w320/ms.png",
    "code": "MS"
  },
  "MT": {
    "name": "Malta",
    "flag": "https://flagcdn.com/w320/mt.png",
    "code": "MT"
  },
  "MU": {
    "name": "Mauritius",
    "flag": "https://flagcdn.com/w320/mu.png",
    "code": "MU"
  },
  "MV": {
    "name": "Maldives",
    "flag": "https://flagcdn.com/w320/mv.png",
    "code": "MV"
  },
  "MW": {
    "name": "Malawi",
    "flag": "https://flagcdn.com/w320/mw.png",
    "code": "MW"
  },
  "MX": {
    "name": "Mexico",
    "flag": "https://flagcdn.com/w320/mx.png",
    "code": "MX"
  },
  "MY": {
    "name": "Malaysia",
    "flag": "https://flagcdn.com/w320/my.png",
    "code": "MY"
  },
  "MZ": {
    "name": "Mozambique",
    "flag": "https://flagcdn.com/w320/mz.png",
    "code": "MZ"
  },
  "NA": {
    "name": "Namibia",
    "flag": "https://flagcdn.com/w320/na.png",
    "code": "NA"
  },
  "NC": {
    "name": "New Caledonia",
    "flag": "https://flagcdn.com/w320/nc.png",
    "code": "NC"
  },
  "NE": {
    "name": "Niger",
    "flag": "https://flagcdn.com/w320/ne.png",
    "code": "NE"
  },
  "NF": {
    "name": "Norfolk Island",
    "flag": "https://flagcdn.com/w320/nf.png",
    "code": "NF"
  },
  "NG": {
    "name": "Nigeria",
    "flag": "https://flagcdn.com/w320/ng.png",
    "code": "NG"
  },
  "NI": {
    "name": "Nicaragua",
    "flag": "https://flagcdn.com/w320/ni.png",
    "code": "NI"
  },
  "NL": {
    "name": "Netherlands",
    "flag": "https://flagcdn.com/w320/nl.png",
    "code": "NL"
  },
  "NO": {
    "name": "Norway",
    "flag": "https://flagcdn.com/w320/no.png",
    "code": "NO"
  },
  "NP": {
    "name": "Nepal",
    "flag": "https://flagcdn.com/w320/np.png",
    "code": "NP"
  },
  "NR": {
    "name": "Nauru",
    "flag": "https://flagcdn.com/w320/nr.png",
    "code": "NR"
  },
  "NU": {
    "name": "Niue",
    "flag": "https://flagcdn.com/w320/nu.png",
    "code": "NU"
  },
  "NZ": {
    "name": "New Zealand",
    "flag": "https://flagcdn.com/w320/nz.png",
    "code": "NZ"
  },
  "OM": {
    "name": "Oman",
    "flag": "https://flagcdn.com/w320/om.png",
    "code": "OM"
  },
  "PA": {
    "name": "Panama",
    "flag": "https://flagcdn.com/w320/pa.png",
    "code": "PA"
  },
  "PE": {
    "name": "Peru",
    "flag": "https://flagcdn.com/w320/pe.png",
    "code": "PE"
  },
  "PF": {
    "name": "French Polynesia",
    "flag": "https://flagcdn.com/w320/pf.png",
    "code": "PF"
  },
  "PG": {
    "name": "Papua New Guinea",
    "flag": "https://flagcdn.com/w320/pg.png",
    "code": "PG"
  },
  "PH": {
    "name": "Philippines",
    "flag": "https://flagcdn.com/w320/ph.png",
    "code": "PH"
  },
  "PK": {
    "name": "Pakistan",
    "flag": "https://flagcdn.com/w320/pk.png",
    "code": "PK"
  },
  "PL": {
    "name": "Poland",
    "flag": "https://flagcdn.com/w320/pl.png",
    "code": "PL"
  },
  "PM": {
    "name": "Saint Pierre and Miquelon",
    "flag": "https://flagcdn.com/w320/pm.png",
    "code": "PM"
  },
  "PN": {
    "name": "Pitcairn",
    "flag": "https://flagcdn.com/w320/pn.png",
    "code": "PN"
  },
  "PR": {
    "name": "Puerto Rico",
    "flag": "https://flagcdn.com/w320/pr.png",
    "code": "PR"
  },
  "PS": {
    "name": "Palestine",
    "flag": "https://flagcdn.com/w320/ps.png",
    "code": "PS"
  },
  "PT": {
    "name": "Portugal",
    "flag": "https://flagcdn.com/w320/pt.png",
    "code": "PT"
  },
  "PW": {
    "name": "Palau",
    "flag": "https://flagcdn.com/w320/pw.png",
    "code": "PW"
  },
  "PY": {
    "name": "Paraguay",
    "flag": "https://flagcdn.com/w320/py.png",
    "code": "PY"
  },
  "QA": {
    "name": "Qatar",
    "flag": "https://flagcdn.com/w320/qa.png",
    "code": "QA"
  },
  "RE": {
    "name": "Reunion",
    "flag": "https://flagcdn.com/w320/re.png",
    "code": "RE"
  },
  "RO": {
    "name": "Romania",
    "flag": "https://flagcdn.com/w320/ro.png",
    "code": "RO"
  },
  "RS": {
    "name": "Serbia",
    "flag": "https://flagcdn.com/w320/rs.png",
    "code": "RS"
  },
  "RU": {
    "name": "Russia",
    "flag": "https://flagcdn.com/w320/ru.png",
    "code": "RU"
  },
  "RW": {
    "name": "Rwanda",
    "flag": "https://flagcdn.com/w320/rw.png",
    "code": "RW"
  },
  "SA": {
    "name": "Saudi Arabia",
    "flag": "https://flagcdn.com/w320/sa.png",
    "code": "SA"
  },
  "SB": {
    "name": "Solomon Islands",
    "flag": "https://flagcdn.com/w320/sb.png",
    "code": "SB"
  },
  "SC": {
    "name": "Seychelles",
    "flag": "https://flagcdn.com/w320/sc.png",
    "code": "SC"
  },
  "SD": {
    "name": "Sudan",
    "flag": "https://flagcdn.com/w320/sd.png",
    "code": "SD"
  },
  "SE": {
    "name": "Sweden",
    "flag": "https://flagcdn.com/w320/se.png",
    "code": "SE"
  },
  "SG": {
    "name": "Singapore",
    "flag": "https://flagcdn.com/w320/sg.png",
    "code": "SG"
  },
  "SH": {
    "name": "Saint Helena",
    "flag": "https://flagcdn.com/w320/sh.png",
    "code": "SH"
  },
  "SI": {
    "name": "Slovenia",
    "flag": "https://flagcdn.com/w320/si.png",
    "code": "SI"
  },
  "SJ": {
    "name": "Svalbard and Jan Mayen",
    "flag": "https://flagcdn.com/w320/sj.png",
    "code": "SJ"
  },
  "SK": {
    "name": "Slovakia",
    "flag": "https://flagcdn.com/w320/sk.png",
    "code": "SK"
  },
  "SL": {
    "name": "Sierra Leone",
    "flag": "https://flagcdn.com/w320/sl.png",
    "code": "SL"
  },
  "SM": {
    "name": "San Marino",
    "flag": "https://flagcdn.com/w320/sm.png",
    "code": "SM"
  },
  "SN": {
    "name": "Senegal",
    "flag": "https://flagcdn.com/w320/sn.png",
    "code": "SN"
  },
  "SO": {
    "name": "Somalia",
    "flag": "https://flagcdn.com/w320/so.png",
    "code": "SO"
  },
  "SR": {
    "name": "Suriname",
    "flag": "https://flagcdn.com/w320/sr.png",
    "code": "SR"
  },
  "SS": {
    "name": "South Sudan",
    "flag": "https://flagcdn.com/w320/ss.png",
    "code": "SS"
  },
  "ST": {
    "name": "Sao Tome and Principe",
    "flag": "https://flagcdn.com/w320/st.png",
    "code": "ST"
  },
  "SV": {
    "name": "El Salvador",
    "flag": "https://flagcdn.com/w320/sv.png",
    "code": "SV"
  },
  "SX": {
    "name": "Sint Maarten",
    "flag": "https://flagcdn.com/w320/sx.png",
    "code": "SX"
  },
  "SY": {
    "name": "Syria",
    "flag": "https://flagcdn.com/w320/sy.png",
    "code": "SY"
  },
  "SZ": {
    "name": "Swaziland",
    "flag": "https://flagcdn.com/w320/sz.png",
    "code": "SZ"
  },
  "TC": {
    "name": "Turks and Caicos Islands",
    "flag": "https://flagcdn.com/w320/tc.png",
    "code": "TC"
  },
  "TD": {
    "name": "Chad",
    "flag": "https://flagcdn.com/w320/td.png",
    "code": "TD"
  },
  "TF": {
    "name": "French Southern Territories",
    "flag": "https://flagcdn.com/w320/tf.png",
    "code": "TF"
  },
  "TG": {
    "name": "Togo",
    "flag": "https://flagcdn.com/w320/tg.png",
    "code": "TG"
  },
  "TH": {
    "name": "Thailand",
    "flag": "https://flagcdn.com/w320/th.png",
    "code": "TH"
  },
  "TJ": {
    "name": "Tajikistan",
    "flag": "https://flagcdn.com/w320/tj.png",
    "code": "TJ"
  },
  "TK": {
    "name": "Tokelau",
    "flag": "https://flagcdn.com/w320/tk.png",
    "code": "TK"
  },
  "TL": {
    "name": "East Timor",
    "flag": "https://flagcdn.com/w320/tl.png",
    "code": "TL"
  },
  "TM": {
    "name": "Turkmenistan",
    "flag": "https://flagcdn.com/w320/tm.png",
    "code": "TM"
  },
  "TN": {
    "name": "Tunisia",
    "flag": "https://flagcdn.com/w320/tn.png",
    "code": "TN"
  },
  "TO": {
    "name": "Tonga",
    "flag": "https://flagcdn.com/w320/to.png",
    "code": "TO"
  },
  "TR": {
    "name": "Turkey",
    "flag": "https://flagcdn.com/w320/tr.png",
    "code": "TR"
  },
  "TT": {
    "name": "Trinidad and Tobago",
    "flag": "https://flagcdn.com/w320/tt.png",
    "code": "TT"
  },
  "TV": {
    "name": "Tuvalu",
    "flag": "https://flagcdn.com/w320/tv.png",
    "code": "TV"
  },
  "TW": {
    "name": "Taiwan",
    "flag": "https://flagcdn.com/w320/tw.png",
    "code": "TW"
  },
  "TZ": {
    "name": "Tanzania",
    "flag": "https://flagcdn.com/w320/tz.png",
    "code": "TZ"
  },
  "UA": {
    "name": "Ukraine",
    "flag": "https://flagcdn.com/w320/ua.png",
    "code": "UA"
  },
  "UG": {
    "name": "Uganda",
    "flag": "https://flagcdn.com/w320/ug.png",
    "code": "UG"
  },
  "UM": {
    "name": "United States Minor Outlying Islands",
    "flag": "https://flagcdn.com/w320/um.png",
    "code": "UM"
  },
  "US": {
    "name": "United States",
    "flag": "https://flagcdn.com/w320/us.png",
    "code": "US"
  },
  "UY": {
    "name": "Uruguay",
    "flag": "https://flagcdn.com/w320/uy.png",
    "code": "UY"
  },
  "UZ": {
    "name": "Uzbekistan",
    "flag": "https://flagcdn.com/w320/uz.png",
    "code": "UZ"
  },
  "VA": {
    "name": "Vatican",
    "flag": "https://flagcdn.com/w320/va.png",
    "code": "VA"
  },
  "VC": {
    "name": "Saint Vincent and the Grenadines",
    "flag": "https://flagcdn.com/w320/vc.png",
    "code": "VC"
  },
  "VE": {
    "name": "Venezuela",
    "flag": "https://flagcdn.com/w320/ve.png",
    "code": "VE"
  },
  "VG": {
    "name": "British Virgin Islands",
    "flag": "https://flagcdn.com/w320/vg.png",
    "code": "VG"
  },
  "VI": {
    "name": "U.S. Virgin Islands",
    "flag": "https://flagcdn.com/w320/vi.png",
    "code": "VI"
  },
  "VN": {
    "name": "Vietnam",
    "flag": "https://flagcdn.com/w320/vn.png",
    "code": "VN"
  },
  "VU": {
    "name": "Vanuatu",
    "flag": "https://flagcdn.com/w320/vu.png",
    "code": "VU"
  },
  "WF": {
    "name": "Wallis and Futuna",
    "flag": "https://flagcdn.com/w320/wf.png",
    "code": "WF"
  },
  "WS": {
    "name": "Samoa",
    "flag": "https://flagcdn.com/w320/ws.png",
    "code": "WS"
  },
  "XK": {
    "name": "Kosovo",
    "flag": "https://flagcdn.com/w320/xk.png",
    "code": "XK"
  },
  "YE": {
    "name": "Yemen",
    "flag": "https://flagcdn.com/w320/ye.png",
    "code": "YE"
  },
  "YT": {
    "name": "Mayotte",
    "flag": "https://flagcdn.com/w320/yt.png",
    "code": "YT"
  },
  "ZA": {
    "name": "South Africa",
    "flag": "https://flagcdn.com/w320/za.png",
    "code": "ZA"
  },
  "ZM": {
    "name": "Zambia",
    "flag": "https://flagcdn.com/w320/zm.png",
    "code": "ZM"
  },
  "ZW": {
    "name": "Zimbabwe",
    "flag": "https://flagcdn.com/w320/zw.png",
    "code": "ZW"
  }
}